import { Button, Dropdown, Spin, Tabs, Tooltip } from 'antd';
import {
  EyeOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import React, { FC, useState } from 'react';
import { analytics, exportReport } from '../../../../services';
import {
  formatDate, formatReportType, generateCSV, generateDataWithFilteredAsset, generateFile,
} from '../../../../utils';
import {
  getAssetFiltersActive,
  getAssetStatusSummary,
  getCurrentFeatureFilters,
  getDataSourceForListView,
  getFilteredAssetsForListView,
  getListViewColumns,
  getPageFeatures,
  getReportGroupsArray,
  getReportKeyedByAssetTypes,
  getReportKeyedByGroups,
  getScratchProjectCode,
  getSelectedAssetType,
  getTenant,
} from '../../../../selectors';
import { CUSTOM_DATE_COLUMNS } from '../../../../config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { TabsProps } from 'antd';
import { report as coreReport } from '@ynomia/core';
import dayjs from 'dayjs';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { getContextStores } from '../../../../context';
import styles from './styles.module.less';



const ReportsDropdown: FC = () => {
  /* Context  */
  const contextStores = getContextStores();
  const {
    timeTravelDate,
  } = getCurrentFeatureFilters(contextStores);

  /* Selectors */
  const filteredAssets = getFilteredAssetsForListView(contextStores);
  const reportGroupsArray = getReportGroupsArray(contextStores);
  const reportKeyedByGroups = getReportKeyedByGroups(contextStores);
  const reportKeyedByAssetTypes = getReportKeyedByAssetTypes(contextStores);
  const selectedAssetType = getSelectedAssetType(contextStores);
  const projectCode = getScratchProjectCode(contextStores);
  const tenant = getTenant(contextStores);
  const dataSource = getDataSourceForListView(contextStores) || [];
  const listViewColumns = getListViewColumns(contextStores);
  const assetStatusSummary = getAssetStatusSummary(contextStores);
  const assetFiltersActive = getAssetFiltersActive(contextStores);
  const { exportReports } = getPageFeatures(contextStores);

  const [downloadingList, updateDownloadingList] = useState<Array<string>>([]);

  const addToDownloadingList = (exportName: string) => updateDownloadingList(
    [...downloadingList, exportName],
  );
  const removeFromDownloadingList = (exportName: string) => updateDownloadingList(
    prevState => prevState.filter(item => exportName !== item),
  );
  const getExportKey = (category, name) => `${category}_${name}`;

  const getReportConfig = (key: string): coreReport.ReportConfigV2 => ({
    report_name: key,
    tenant,
    project: projectCode,
    csv: '1',
  });

  const onClickExport = async (category: string, name: string) => {
    if (!name) return;
    const exportKey = getExportKey(category, name);
    addToDownloadingList(exportKey);
    analytics.trackEvent('Export Clicked', { category, name });

    const report = getReportConfig(name);
    const file = await exportReport(report);
    const { data, fileName, type } = file;
    generateFile(
      data,
      fileName,
      type,
    );
    removeFromDownloadingList(exportKey);
  };

  const generateFilteredReport = async (name) => {
    const report = getReportConfig(name);
    const file = await exportReport(report);

    const { data, fileName, type } = file;
    const filteredAssetData = generateDataWithFilteredAsset(data, filteredAssets);

    generateFile(
      filteredAssetData,
      fileName,
      type,
    );
  };

  const getVisibleViewCSV = () => {
    const headerTitles: Array<string> = listViewColumns.map(({ title }) => `${title}`);
    const headerKeys = listViewColumns.map(({ key }) => key as string);
    const listViewColumnsByKey = new Map(listViewColumns.map(column => [column.key, column]));

    const res = dataSource.map((data) => {
      return headerKeys.map((key) => {
        const custom = listViewColumnsByKey.get(key)?.custom || null;
        const value = data[key];
        if (CUSTOM_DATE_COLUMNS.includes(custom || '')) {
          return `${formatDate(value)}`;
        }
        return value;
      });
    });

    return generateCSV(headerTitles, res);
  };

  const getStatusSummaryCSV = () => {
    const headerTitles = ['Status', 'Current %', 'Current #', 'Cumulative %', 'Cumulative #'];

    const headerKeys = [
      'label',
      'currentOccurrencePercentage',
      'currentOccurrence',
      'cumulativeOccurrencePercentage',
      'cumulativeOccurrence',
    ];

    const statusSummaryCSV = assetStatusSummary.map((data) => {
      return headerKeys.map((dataIndex) => {
        return data[dataIndex] || 0;
      });
    });

    return generateCSV(headerTitles, statusSummaryCSV);
  };

  const onClickFilteredExport = async (name) => {
    if (!name) return;
    const exportKey = getExportKey('current_view', name);
    addToDownloadingList(exportKey);

    switch (name) {
      case 'visible_list_view':
        generateFile(
          getVisibleViewCSV(),
          `visible_view_${tenant}_${projectCode}_${dayjs().format('YYYY-MM-DD')}`,

        );
        break;
      case 'status_summary':
        generateFile(
          getStatusSummaryCSV(),
          `status_summary_${tenant}_${projectCode}_${dayjs().format('YYYY-MM-DD')}`,
        );
        break;
      default:
        await generateFilteredReport(name);
    }

    removeFromDownloadingList(exportKey);
  };

  const renderExportButton = (
    category: string,
    name: string,
    text: string,
    onClick: () => void,
    disabled: boolean,
  ) => {
    const exportKey = getExportKey(category, name);
    const isLoading = downloadingList.includes(exportKey);
    return <button
      key={exportKey}
      className={styles.exportButton}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      <div key={exportKey} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {text} {isLoading && <Spin size="small" />}
      </div>
    </button>;
  };

  const reportCurrentViewMenu = (): Array<JSX.Element> => {
    const current: Array<JSX.Element> = [
      <div key='this_page' className={styles.titleContainer}>
        <span className={styles.title}>This Page</span>
      </div>,
      renderExportButton(
        'current_view',
        'visible_list_view',
        'Visible List View',
        () => onClickFilteredExport('visible_list_view'),
        false,
      ),
      renderExportButton(
        'current_view',
        'status_summary',
        'Status Summary',
        () => onClickFilteredExport('status_summary'),
        false,
      ),
      <div key='filtered_reports' className={styles.titleContainer}>
        <span className={styles.title}>Filtered Reports</span>
        <Tooltip
          title={'Filter the list view table to export filtered reports.'}
          placement="topRight"
        >
          <InfoCircleOutlined />
        </Tooltip>
      </div>,
    ];

    const reportCurrentAssetType = reportKeyedByAssetTypes.get(selectedAssetType.id) || [];

    const items = reportCurrentAssetType.map(({ name, label }) => {
      return renderExportButton(
        'current_view',
        name,
        label,
        () => onClickFilteredExport(name),
        !assetFiltersActive,
      );
    });
    return [...current, ...items];
  };

  const reportMenu = (): TabsProps['items'] => {
    const items: TabsProps['items'] = [
      {
        key: 'current-view',
        label: (
          <span>
            <EyeOutlined />
            <span>Current View</span>
          </span>
        ),
        children: <div className={styles.dropDownMenuContainer}>
          {reportCurrentViewMenu()}
        </div>,
      },
    ];

    reportGroupsArray.forEach((reportType) => {
      const reportExports = reportKeyedByGroups.get(reportType);
      items.push({
        key: reportType,
        label: (
          <span>
            <FileTextOutlined />
            <span>{formatReportType(reportType)}</span>
          </span>
        ),
        children: <div className={styles.dropDownMenuContainer}>
          {reportExports?.map(({ label, name }) =>
            renderExportButton(
              formatReportType(reportType),
              name,
              label,
              () => onClickExport(formatReportType(reportType), name),
              false,
            ),
          )}
        </div>,
      });
    });
    return items;
  };

  const renderVisibleListExportButton = () => {
    return <Button
      onClick={() => onClickFilteredExport('visible_list_view')}
    >
      Export {
        downloadingList.length
          ? <Spin size="small" className={styles.spinner} />
          : <FontAwesomeIcon icon={faFileCsv} style={{ marginLeft: 4 }} />
      }
    </Button>;
  };

  return exportReports ? <Dropdown
    trigger={['click']}
    disabled={!!timeTravelDate}
    dropdownRender={() => (
      <div className={styles.dropDownMenu}>
        <Tabs
          className="reportsDropdownCategoryTabs"
          tabPosition={'left'}
          items={reportMenu()}
        />
      </div>
    )}>
      <Button>
        Export {
          downloadingList.length
            ? <Spin size="small" className={styles.spinner} />
            : <FontAwesomeIcon icon={faFileCsv} style={{ marginLeft: 4 }} />
        }
      </Button>
    </Dropdown> : renderVisibleListExportButton();
};

export default ReportsDropdown;