import {
  SET_ACCESS_RULE,
  SET_ACCESS_RULES,
  SET_CREATING_NEW_ACCESS_RULE_LOADING,
  SET_FETCHING_ACCESS_RULES,
  SET_FILTERS_ACCESS_RULES_TABLE,
  SET_SEARCH_ACCESS_RULES,
  SET_UPDATING_ACCESS_RULE_LOADING,
} from '../actions/actionsTypes';

export default (state, action) => {
  switch (action.type) {
    case SET_ACCESS_RULE:
      return {
        ...state,
        accessRulesKeyedById: state.accessRulesKeyedById.set(
          action.payload.id,
          action.payload,
        ),
      };
    case SET_ACCESS_RULES:
      return {
        ...state,
        accessRulesKeyedById: action.payload,
      };
    case SET_FETCHING_ACCESS_RULES:
      return {
        ...state,
        isFetchingAccessRules: action.payload,
      };
    //POST
    case SET_CREATING_NEW_ACCESS_RULE_LOADING:
      return {
        ...state,
        isCreatingNewAccessRule: action.payload,
      };

    //PATCH
    case SET_UPDATING_ACCESS_RULE_LOADING:
      return {
        ...state,
        isUpdatingAccessRule: action.payload,
      };

    case SET_SEARCH_ACCESS_RULES:
      return {
        ...state,
        searchAccessRules: action.payload,
      };

    case SET_FILTERS_ACCESS_RULES_TABLE:
      return {
        ...state,
        filtersAccessRulesTable: action.payload,
      };

    default:
      return state;
  }
};
