import {
  SET_CREATING_VISIBILITY_FILTER_LOADING,
  SET_FETCHING_VISIBILITY_FILTER,
  SET_UPDATING_VISIBILITY_FILTER_LOADING,
  SET_VISIBILITY_FILTER,
} from '../actions/actionsTypes';

export default (state, action) => {
  switch (action.type) {
    //GET
    case SET_VISIBILITY_FILTER:
      return {
        ...state,
        visibilityFiltersKeyedById: action.payload,
      };
    case SET_FETCHING_VISIBILITY_FILTER:
      return {
        ...state,
        isFetchingVisibilityFilter: action.payload,
      };
    //POST
    case SET_CREATING_VISIBILITY_FILTER_LOADING:
      return {
        ...state,
        isCreatingNewVisibilityFilter: action.payload,
      };
    //PATCH
    case SET_UPDATING_VISIBILITY_FILTER_LOADING:
      return {
        ...state,
        isUpdatingvisibilityFilter: action.payload,
      };

    default:
      return state;
  }
};
