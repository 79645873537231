import {
  SET_IS_LOADING_PERCENTAGE,
  SET_IS_TWIN_LOADING,
  SET_IS_TWIN_READY,
} from '../actions/actionsTypes';

export default (state, action) => {
  switch (action.type) {
    case SET_IS_TWIN_READY:
      return {
        ...state,
        isTwinsReady: action.payload,
      };
    case SET_IS_TWIN_LOADING:
      return {
        ...state,
        isTwinLoading: action.payload,
      };
    case SET_IS_LOADING_PERCENTAGE:
      return {
        ...state,
        loadingPercentage: action.payload,
      };
    default:
      return state;
  }
};
