import React, { createContext, useContext, useReducer } from 'react';
import ClientCacheReducer from '../reducers/clientCache';
import { ContextDispatch } from '../config/types';
import { InMemoryCacheData } from '@ynomia/client';

export type ClientCacheStateType = {
  clientCache: InMemoryCacheData | null
};

const initialState: ClientCacheStateType = {
  clientCache: null,
};

export const ClientCacheContext = createContext({ state: initialState, dispatch: ({}) => {} });

export const useClientCache = (): [ClientCacheStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(ClientCacheContext);
  return [state, dispatch];
};

export const ClientCacheProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ClientCacheReducer, initialState);

  return (
    <ClientCacheContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </ClientCacheContext.Provider>
  );
};
