import { AccessRule, ContextDispatch } from '../config/types';
import React, { createContext, useContext, useReducer } from 'react';
import AccessRuleReducer from '../reducers/accessRules';
import { FilterValue } from 'antd/es/table/interface';

export type AccessRuleStateType = {
  accessRulesKeyedById: Map<string, AccessRule>
  isFetchingAccessRules: boolean
  isCreatingNewAccessRule: boolean
  isUpdatingAccessRule: boolean
  searchAccessRules: string | null
  filtersAccessRulesTable: Record<string, FilterValue | null>
};

const initialState: AccessRuleStateType = {
  accessRulesKeyedById: new Map(),
  searchAccessRules: null,
  isFetchingAccessRules: false,
  isCreatingNewAccessRule: false,
  isUpdatingAccessRule: false,
  filtersAccessRulesTable: {},
};

export const AccessRuleContext = createContext({ state: initialState, dispatch: ({}) => {} });

export const useAccessRule = (): [AccessRuleStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(AccessRuleContext);
  return [state, dispatch];
};

export const AccessRuleProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AccessRuleReducer, initialState);

  return (
    <AccessRuleContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </AccessRuleContext.Provider>
  );
};
