import {
  DEFAULT_ASSET_PAGE_FEATURES,
  IS_MOBILE,
  STATUS_TRACKING_PAGE_FEATURES,
} from '../config/constants';
import { createSelector } from 'reselect';
import map from './map';

export const getCurrentFeature = createSelector(
  [map.currentFeature],
  (currentFeature) => {
    return currentFeature;
  });

export const getPageFeatures = createSelector(
  [
    getCurrentFeature,
  ],
  (feature) => {
    return feature === 'status_tracking' ?
      STATUS_TRACKING_PAGE_FEATURES : DEFAULT_ASSET_PAGE_FEATURES;
  },
);

export const getCurrentFeatureFilters = createSelector(
  [
    getCurrentFeature,
    map.filtersByFeature,
  ], (currentFeature, filtersByFeature) => {
    if (!filtersByFeature || !filtersByFeature[currentFeature]) {
      return {
        searchTags: [],
        searchText: '',
        dateFilter: [],
        typeFilter: '',
        assetStatusFilter: {
          toggle: null,
          ids: [],
        },
        observationTypeFilter: new Map(),
        assetLayerFilter: [],
        multiSearchModeFilter: 'or',
        layerChildFilter: null,
        tableFilter: {},
        pagination: {
          current: 1,
          pageSize: IS_MOBILE ? 10 : 20,
        },
        supplierFilter: [],
        tableSorter: null,
        timeTravelDate: null,
      };
    }

    return filtersByFeature[currentFeature];
  },
);

