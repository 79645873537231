
import {
  AccessRule,
  Asset,
  AssetStatusFilter,
  AssetTableColumn,
  Layer,
  RawObservationRecord,
  ToggleObservation,
} from '../config/types';
import dayjs, { Dayjs } from 'dayjs';
import { getLayerPath, isFilteredByDateFilter } from '.';
import { AssetStatus } from '@ynomia/core/dist/blueprint';
import { FilterValue } from 'antd/es/table/interface';
import { escapeRegexStr } from '@ynomia/core/dist/utils';


export const checkSearchFilter = (
  asset: Asset,
  searchTags: Array<string>,
  searchText: string | undefined,
  multiSearchModeFilter: 'or' | 'and' | undefined,
  layersKeyedById: Map<string, Layer>,
  assetStatusesKeyedById,
  assetToTest: Record<string, any>,
): boolean => {
  const { fields, status, destination } = asset || {};

  const layerID = destination?.layerID || null;
  const twinID = destination?.twinID || null;
  const layerPath = getLayerPath(layersKeyedById, layerID);
  const assetStatus = (status && assetStatusesKeyedById.get(status)) || {};
  const assetValues = Object.values({
    status: assetStatus?.label,
    twinID,
    ...fields,
    ...assetToTest,
    ...layerPath,
  });

  const assertValueString = JSON.stringify(assetValues);
  const searchTextTags = searchText ? [searchText, ...searchTags] : searchTags;

  if (searchTextTags.length <= 1 ) {
    return !!assertValueString.match(
      new RegExp(
        escapeRegexStr(searchTextTags.join()),
        'gi',
      ),
    );
  } else if (multiSearchModeFilter === 'and') {
    return searchTextTags.every(q => assertValueString.toLowerCase().includes(q.toLowerCase()));
  } else {
    return !!assertValueString?.match(
      new RegExp(
        searchTextTags.map(text => text.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')).join('|'),
        'gi',
      ),
    );
  }
};

export const checkIsFilteredByDate = (
  asset: Asset,
  dateFilter: [Dayjs, Dayjs] | [],
  assetStatusFilter: AssetStatusFilter | undefined,
): boolean => {
  const { observations } = asset;

  if (!observations?.length && dateFilter.length === 2) return false;
  if (dateFilter.length !== 2) return true;
  if (!observations) return false;

  if (assetStatusFilter?.ids?.length) {
    if (assetStatusFilter.toggle === 'cumulative') {
      return observations.filter(({ to })=> {
        return to && assetStatusFilter.ids.includes(to);
      }).some(({ date }) => {
        const dateFormat = date && new Date(date);
        return !!dateFormat
          && isFilteredByDateFilter(dateFormat, dateFilter);
      });
    }
    /**
     * Code reaches here for the list view current column
     **/
    return assetStatusFilter.ids.some((filterStatus) => {
      const firstObservationWithStatus = observations.find(({ to })=> {
        return to && filterStatus === to;
      });
      const obsDate = firstObservationWithStatus?.date;
      const dateFormat = obsDate && new Date(obsDate);

      return !!dateFormat
        && isFilteredByDateFilter(dateFormat, dateFilter)
        && !!firstObservationWithStatus.isCurrentStatus;
    });
  } else {
    /**
     * Code reaches here for the status summary with a date filter.
     * We want to show assets that have any observation in the date range.
     **/
    return observations
      .some(({ date }) => {
        const dateFormat = date && new Date(date);
        return dateFormat && isFilteredByDateFilter(dateFormat, dateFilter);
      });
  }
};

export const checkIsFilteredByStatus = (
  asset: Asset,
  assetStatusesKeyedById: Map<string, AssetStatus>,
  assetStatusFilter: AssetStatusFilter,
  defaultStatus?: AssetStatus,
): boolean => {
  const { status, observations } = asset;
  const { ids, toggle } = assetStatusFilter || {};
  const assetStatus = status && (assetStatusesKeyedById.get(status) as AssetStatus);

  if (status && ids.includes(status)) {
    return true;
  }
  if (assetStatus && (toggle === 'cumulative')) {
    if (ids.includes(defaultStatus?.id || '')) return true;
    return !!observations?.find((observation) => {
      if (observation && observation?.to) {
        return assetStatusFilter.ids.includes(observation?.to);
      } else {
        return false;
      }
    });
  }
  return false;
};

export const checkIsFilteredByObservationType = (
  asset: Asset,
  observationTypeFilter: Map<string, ToggleObservation> | undefined,
  observationTypesKeyedByAssetId: Map<string, Array<string> | undefined>,
): boolean => {

  const { id } = asset;
  const observationTypesFromAsset = observationTypesKeyedByAssetId.get(id) || [];

  const observationTypeFilterResult: Array<boolean> = [];

  observationTypeFilter?.forEach((value, key) => {
    if (value === 'with') {
      observationTypeFilterResult.push(observationTypesFromAsset.includes(key));
    } else {
      observationTypeFilterResult.push(!!!observationTypesFromAsset.includes(key));
    }
  });

  return !!!observationTypeFilterResult.includes(false);
};

export const checkIsFilteredByTable = (
  asset: Asset,
  linkedDevicesKeyedByAssetId,
  tableFilter: Record<string, FilterValue | null> | undefined,
  listViewColumns: Array<AssetTableColumn>,
  assetToTest: Record<string, any>,
): boolean => {
  const isTableFilterIsEmpty = tableFilter && Object.values(tableFilter).every(x => x === null);

  let isFilteredByTable = true;

  if (!tableFilter || isTableFilterIsEmpty) {
    return true;
  }

  const tableFilterKeys:Array<string> = [];
  Object.keys(tableFilter).forEach((key) => {
    const valueTableFilter = tableFilter[key];
    if (valueTableFilter) {
      tableFilterKeys.push(key);
    }
  });

  tableFilterKeys.some((key) => {
    const listViewColumn = listViewColumns.find(col => col.key === key);
    const customDateFormats = ['formatDate', 'observationTypeDate'];
    if (tableFilter[key] && customDateFormats.includes(listViewColumn?.custom || '')) {
      const [tableFilterValue] = tableFilter[key] as FilterValue;
      const [start, end] = (tableFilterValue as string).split(',');
      isFilteredByTable = isFilteredByDateFilter(
        new Date(assetToTest[key]),
        [dayjs(start), dayjs(end)],
      );
    } else if (tableFilter[key] && listViewColumn?.custom === 'tagLink') {
      const device = !!linkedDevicesKeyedByAssetId.get(asset.id);
      isFilteredByTable = !!(tableFilter[key]?.includes(device));
    } else if (!tableFilter[key]?.includes(assetToTest[key] || '')) {
      isFilteredByTable = false;
    }
  });

  return isFilteredByTable;
};

export const checkIsFilteredByLayer = (
  layerChildFilter: Layer | null | undefined,
  layersKeyedById: Map<string, Layer>,
  layerID: string | null,
  layerIdsKeyedByAncestorId: Map<string, Set<string>>,
): boolean => {
  const assetLayer = layersKeyedById.get(layerID!);
  const { parentID, isRoot } = assetLayer || {};

  if (isRoot) {
    return false;
  } else if (!parentID) {
    return false;
  } else {
    return layerIdsKeyedByAncestorId.get(layerChildFilter?.id!)!?.has(layerID!);
  }
};

export const checkSearchAccessRulesFilter = (
  accessRule: AccessRule,
  searchAccessRules: string | null,
): boolean => {
  const { notes, createdAt, roles, filters, name } = accessRule || {};
  const { email } = accessRule?.user || {};

  if (searchAccessRules === null) return true;

  const userValues = [...Object.values({
    email,
    name,
    notes,
    roles,
    filters,
  }), dayjs(createdAt).format('MMM DD YYYY hh:mma')];

  const filterRegex = new RegExp(escapeRegexStr(searchAccessRules?.trim()), 'gi');
  return !!JSON.stringify(userValues)?.match(filterRegex);
};

export const checkAccessRulesFilteredByTableFilter = (
  accessRule: AccessRule,
  filtersAccessRulesTable: Record<string, FilterValue | null>,
): boolean => {
  const filterAccessRules = {};
  const filtersAccessRulesTableKeys = Object.keys(filtersAccessRulesTable);

  if (!filtersAccessRulesTableKeys.length) return true;

  filtersAccessRulesTableKeys.forEach((key) => {
    if (filtersAccessRulesTable[key]) {
      filterAccessRules[key] = filtersAccessRulesTable[key];
    }
  });

  const filterKeys = Object.keys(filterAccessRules);
  const flattenAccessRule = { ...accessRule, ...accessRule.user };

  const isFiltered = filterKeys.every((key) => {
    return filterAccessRules[key]?.includes(flattenAccessRule[key]);
  });

  return isFiltered;
};

export const checkIsFilteredBySupplier = (
  asset: Asset,
  supplierFilter: Array<string>,
): boolean => {
  const { fields } = asset;
  const { supplier } = fields || {};
  const isSupplierEmpty = supplier === '' || supplier === undefined;

  return isSupplierEmpty ? supplierFilter.includes('(blank)') : supplierFilter.includes(supplier);
};

export const getObservationSameOrBeforeTimeTravelDate = (
  asset: Asset,
  timeTravelDate: Dayjs,
): Array<RawObservationRecord> => {
  if (!asset) return [];
  const timeTravelDateMs = timeTravelDate.toDate().getTime();
  const { observations } = asset;
  if (!observations) return [];

  const filteredObservation = observations.filter(({ date, created }) => {
    return timeTravelDateMs >= new Date(date || created).getTime();
  });

  return filteredObservation;
};

