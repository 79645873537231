import React, { FC, useEffect } from 'react';
import { getAnnouncement, getScratchProjectCode, getTenant, getUserInfo } from '../../../selectors';
import { Alert } from 'antd';
import { dismissAnnouncement } from '../../../services';
import { getContextStores } from '../../../context';
import { getUser } from '../../../actions';

const Announcement: FC = () => {
  /* Context  */
  const contextStores = getContextStores();
  const {
    userDispatch,
    assetState,
    userState,
  } = contextStores;

  const { lastFetchStartTime } = assetState;
  const { firstFetchAsset } = assetState;

  /* Selectors  */
  const announcement = getAnnouncement(contextStores);
  const projectCode = getScratchProjectCode(contextStores);

  const userInfo = getUserInfo(contextStores);
  const tenant = getTenant(contextStores);

  const projectMeta = userState.user.project_meta || [];

  const [currentProjectMeta] = projectMeta?.filter(({ project }) => {
    return projectCode === project;
  }) || {};
  const { text, id } = announcement?.message || {};

  const afterClose = () => {
    if (id) {
      dismissAnnouncement(tenant, projectCode, id, userInfo.id);
    }
  };

  const showAnnouncement = (
    firstFetchAsset
    && announcement?.enabled
    && !currentProjectMeta?.dismissed_announcement_ids.includes(id)
  );

  useEffect(() => {
    getUser(userInfo.id, userDispatch);
  }, [lastFetchStartTime]);

  return showAnnouncement ? <Alert
    message={text}
    banner
    showIcon={false}
    closable
    onClose={afterClose}
  /> : <></>;
};
export default Announcement;