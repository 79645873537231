import { Button, Timeline, TimelineItemProps, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined, PlusCircleFilled } from '@ant-design/icons';
import React, { FC, useEffect, useState } from 'react';
import {
  checkIsStatusUpdateEnabled,
  getAssetStatusesKeyedById,
  getAssetTypesById,
  getCurrentFeatureFilters,
  getLayerTypesKeyedById,
  getProjectUserListKeyedById,
  getSelectedAssetType,
  getTimeTravelAssetsById,
} from '../../../../selectors';
import AddNewObservation from './AddNewObservation';
import { DEFAULT_STATUS_COLOR } from '../../../../config/constants';
import EditObservation from './EditObservation';
import { Field } from '@ynomia/core/dist/blueprint';
import FieldValues from '../../../atoms/FieldValues';
import ObservationItem from './ObservationItem';
import { ObservationListItem } from '../../../../config/types/assets';
import { TimelineIcon } from '../../../atoms';
import { analytics } from '../../../../services';
import dayjs from 'dayjs';
import { generateAssetObservationList } from '../../../../utils/asset';
import { getContextStores } from '../../../../context';
import { getLayerPath } from '../../../../utils';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import styles from './styles.module.less';
import { useExpandingTimeline } from '../../../../hooks';

dayjs.extend(isSameOrBefore);

const { Title } = Typography;

interface Props {
  assetId: string
}

const AssetDetails: FC<Props> = ({ assetId }) => {
  /* Context  */
  const contextStores = getContextStores();
  const { layerState } = contextStores;

  /* Selectors */
  const assetStatusesKeyedById = getAssetStatusesKeyedById(contextStores);
  const selectedAssetType = getSelectedAssetType(contextStores);
  const layerTypesKeyedById = getLayerTypesKeyedById(contextStores);
  const projectUserListKeyedById = getProjectUserListKeyedById(contextStores);
  const assetTypesById = getAssetTypesById(contextStores);
  const isStatusUpdateEnabled = checkIsStatusUpdateEnabled(contextStores);
  const timeTravelAssetsById = getTimeTravelAssetsById(contextStores);
  const currentFeatureFilters = getCurrentFeatureFilters(contextStores);
  const { layersKeyedById } = layerState;
  const { timeTravelDate } = currentFeatureFilters;

  const [isAddNewObservation, setAddNewObservation] = useState<boolean>(false);

  const canHaveDestination = selectedAssetType?.can_have_destination;
  const selectedAssetTypeFields = selectedAssetType?.fields;
  const currentAsset = timeTravelAssetsById.get(assetId)!;
  const { destination, observations } = currentAsset;
  const layerID = destination?.layerID || null;
  const [twinId] = destination?.twinID || '';
  const layer = layerID ? layersKeyedById.get(layerID) : null;

  useEffect(() => {
    analytics.trackEvent(
      'Asset Details Viewed',
      { asset_type: selectedAssetType?.id },
    );
  }, []);

  let observationList = generateAssetObservationList(
    assetTypesById,
    assetStatusesKeyedById,
    selectedAssetType,
    projectUserListKeyedById,
    observations,
  );

  const expandController = useExpandingTimeline([], 'key', false);

  const layerPath = getLayerPath(layersKeyedById, layer?.id);

  const renderDestinationField = () => {
    const fieldSchema: Partial<Field>[] = [];
    const fieldValues: { [fieldId: string]: any } = {};
    layerPath.forEach((l) => {
      let label = layerTypesKeyedById.get(l.type)?.name || '';
      const isDestination = layer?.type === l.type;
      if (isDestination) label = `${label} (Destination)`;
      fieldSchema.push({
        id: label,
        label,
        fieldType: 'text',
      });
      fieldValues[label] = l.label;
    });
    const twinIDFieldLabel = 'TwinID/Model location Ref';
    fieldSchema.push({
      id: twinIDFieldLabel,
      label: twinIDFieldLabel,
      fieldType: 'text',
    });
    fieldValues[twinIDFieldLabel] = twinId;
    return (
      <FieldValues
        schema={fieldSchema as Field[]}
        values={fieldValues}
        asset={currentAsset}
        display={{ gutter: [16, 16], labelColSpan: 10, valueColSpan: 14 }}
      />
    );
  };

  const renderTimeLineLabel = (observation: ObservationListItem) => {
    const { isAutoFill, canEditDate } = observation;
    const label = isAutoFill ? '' : observation.timestamp_human_readable;
    return label ? <div style={{ display: 'inline-flex' }}>
      <div>{label}</div>
        {canEditDate && <EditObservation observation={observation} />}
        </div> : <>
        {isStatusUpdateEnabled && <EditObservation observation={observation} />}
      </>;
  };

  const getItems = () => {
    let items: TimelineItemProps[] = [];

    if (isStatusUpdateEnabled) {
      const tooltip = !!timeTravelDate
        ? "Sorry, You can\'t add new event while you have Time Travel active"
        : '';
      items = [{
        color: DEFAULT_STATUS_COLOR,
        key: 'added_to_ynomia',
        label: ' ',
        dot:(
          <Tooltip
            title={tooltip}
          >
            <Button
              size='small'
              type="text"
              shape="circle"
              disabled={!!timeTravelDate}
              onClick={() => setAddNewObservation(!isAddNewObservation)}
              icon={<PlusCircleFilled style={{ fontSize: 16, color: '#999' }} />}
              className="addNewEventHoverOverride"
            />
          </Tooltip>
        ),
        children: <AddNewObservation
          disabled={!!timeTravelDate}
          tooltip={tooltip}
          showModal={setAddNewObservation}
          isModalOpened={isAddNewObservation}
          assetId={assetId}
        />,
      }];
    }

    if (!observations?.length) {
      return [...items,
        {
          color: DEFAULT_STATUS_COLOR,
          key: 'added_to_ynomia',
          label: ' ',
          children: <div>
            Added to Ynomia
            <Tooltip
              title={'We don\'t have any history or events for this asset. '
              + 'Data is catured and added in real-time, so check again soon.'}
            >
              <InfoCircleOutlined style={{ marginTop: 4, marginLeft: 4 }}/>
            </Tooltip>
          </div>,
        },
      ];
    }
    return [...items, ...observationList?.map((observation) => {
      const {
        action,
        fontColor,
        statusColor,
        tooltip,
      } = observation;

      const key = JSON.stringify(observation);
      const isOpened = expandController.expandedRowKeys.includes(key);
      return {
        dot: action !== 'NONE' ? <TimelineIcon
          isOpened={isOpened}
          onClick={()=> expandController.toggleRowExpanded(key)}
        /> : null,
        key: key,
        label:
          <Tooltip
            title={tooltip ?? ''}
            placement="topRight"
          >
            {renderTimeLineLabel(observation)}
          </Tooltip>,
        color: statusColor || DEFAULT_STATUS_COLOR,
        children: <div style={{ backgroundColor: '#FAFAFA', color: fontColor || '#000000e0' }}>
          <ObservationItem
            asset={currentAsset}
            expandedKey={key}
            observation={observation}
            expandController={expandController}
          />
        </div>,
      };
    })];
  };

  const renderTimeline = () => {
    return (
      <Timeline
        mode={'left'}
        items={getItems()}
      />
    );
  };

  const renderFields = () => {
    if (!selectedAssetTypeFields) { return <div />; }
    return (
      <FieldValues
        schema={selectedAssetTypeFields}
        values={currentAsset.fields || {}}
        asset={currentAsset}
        display={{ gutter: [16, 16], labelColSpan: 10, valueColSpan: 14 }}
      />
    );
  };

  return (
    <div style={{ paddingRight: 48, paddingLeft: 48 }} key={assetId}>
      <Title level={5}>Properties</Title>
      <div>
        {canHaveDestination && renderDestinationField()}
      </div>
      <div>
        {renderFields()}
      </div>
      <div>
        <Title level={5}>History</Title>
        <div className={styles.timelineContainer}>
          {renderTimeline()}
        </div>
      </div>
    </div>
  );
};

export default AssetDetails;