import { SET_FETCHING_USER, SET_USER } from '../actions/actionsTypes';

export default (state, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_FETCHING_USER:
      return {
        ...state,
        isFetchingUser: action.payload,
      };
    default:
      return state;
  }
};
