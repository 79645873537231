import {
  SET_ASSETS,
  SET_FETCHING_ASSETS,
  SET_FIRST_FETCH_ASSSET,
  SET_LAST_FETCH_ERROR,
  SET_LAST_FETCH_START_TIME,
  SET_UPDATING_OBSERVATION,
} from '../actions/actionsTypes';
import { Asset } from '../config/types';
import { AssetStateType } from '../context';

export default (state: AssetStateType, action): AssetStateType => {
  switch (action.type) {
    case SET_ASSETS:
      const assetsKeyedById = new Map<string, Asset>([
        ...state.assetsKeyedById,
        ...action.payload,
      ]);

      assetsKeyedById.forEach(
        (asset) => {
          const { isArchived, id } = asset!;
          if (isArchived) {
            assetsKeyedById.delete(id);
          }
        },
      );

      return {
        ...state,
        assetsKeyedById,
        lastFetchFailed: false,
      };
    case SET_LAST_FETCH_ERROR:
      return {
        ...state,
        lastFetchFailed: true,
      };
    case SET_FETCHING_ASSETS:
      return {
        ...state,
        isFetchingAssets: action.payload,
      };
    case SET_UPDATING_OBSERVATION:
      return {
        ...state,
        isUpdatingObservation: action.payload,
      };
    case SET_LAST_FETCH_START_TIME:
      return {
        ...state,
        lastFetchStartTime: action.payload,
      };
    case SET_FIRST_FETCH_ASSSET:
      return {
        ...state,
        firstFetchAsset: true,
      };
    default:
      return state;
  }
};
