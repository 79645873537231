import React, { createContext, useContext, useReducer } from 'react';
import { AssetUpdateMode } from '../config/constants';
import AssetUpdateReducer from '../reducers/assetUpdate';
import { ContextDispatch } from '../config/types';

export type AssetUpdateStateType = {
  isPlanningAsset: boolean
  isApplyingAsset: boolean
  plannedUpdatedAsset: any | null
  fileName: string | null
  importTime: Date | null;
  fileContents: string | null
  assetUpdateMode: AssetUpdateMode
  filtersAssetUpdate: Array<string>
  filtersAssetUpdateTable: Array<string>
  showChangesOnly: boolean
  error: {
    status: number
    body: {
      code: string
      msg: string
    }
  } | null
};

const initialState: AssetUpdateStateType = {
  isPlanningAsset: false,
  isApplyingAsset: false,
  plannedUpdatedAsset: null,
  fileName: null,
  importTime: null,
  fileContents: null,
  assetUpdateMode: 'upsert',
  filtersAssetUpdate: [],
  filtersAssetUpdateTable: [],
  showChangesOnly: false,
  error: null,
};

export const AssetUpdateContext = createContext({ state: initialState, dispatch: ({}) => {} });

export const useAssetUpdate = (): [AssetUpdateStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(AssetUpdateContext);
  return [state, dispatch];
};

export const AssetUpdateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AssetUpdateReducer, initialState);

  return (
    <AssetUpdateContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </AssetUpdateContext.Provider>
  );
};
