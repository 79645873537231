import { Avatar, Dropdown, MenuProps } from 'antd';
import { CheckOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import {
  LOGIN_HINT, PROJECT_ID_STORAGE_KEY, REDIRECT_TO, REGION,
} from '../../../config/constants';
import React, { FC } from 'react';
import { currentRegion, hostnameOptions } from '../../../config';
import {
  getProjectName,
  getProjects,
  getTenant,
  getUserInfo,
} from '../../../selectors';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Header } from 'antd/es/layout/layout';
import { MenuItem } from '../../atoms';
import { UserInfo } from '@ynomia/client';
import { client } from '../../../services';
import { getContextStores } from '../../../context';
import styles from './styles.module.less';

type Props = {
  children: React.ReactNode
};

const MenuHeader: FC<Props> = ({ children }) => {
  /* Context  */
  const contextStores = getContextStores();

  /* Selectors */
  const projectName = getProjectName(contextStores);
  const currentTenant = getTenant(contextStores);
  const userInfo = getUserInfo(contextStores);
  const projects = getProjects(contextStores);

  const { email, picture } = userInfo as UserInfo;
  let menuProjects: MenuProps['items'] = projects?.filter(
    ({ isArchived }) => !isArchived,
  ).map(({ name, id, tenant }) => {
    return {
      label: `${name} (${tenant})`.toUpperCase(),
      key: id,
    };
  });

  const menuArchivedProjects: MenuProps['items'] = projects?.filter(
    ({ isArchived }) => isArchived,
  ).map(({ name, id, tenant }) => {
    return {
      label: `${name} (${tenant})`.toUpperCase(),
      key: id,
    };
  });


  if (menuArchivedProjects.length) {
    menuProjects = [...menuProjects,
      {
        label: <span className={styles.archivedLabel}>Archived</span>,
        key: 'archived',
        children: menuArchivedProjects,
      }];
  }

  const history = useNavigate();
  const [searchParams] = useSearchParams();

  const onSwitchRegion = (urlRegionCode: string) => {
    searchParams.append(REGION, urlRegionCode);
    localStorage.setItem(LOGIN_HINT, email);
    // Hard refresh page as we want to read from new localstorage value
    window.location.href = `/redirect?${searchParams}`;
  };

  const onClick: MenuProps['onClick'] = (e) => {
    if (!e.key) return;
    searchParams.append(PROJECT_ID_STORAGE_KEY, e.key);
    searchParams.append(REDIRECT_TO, location.pathname);
    history(`/redirect?${searchParams}`, { replace: true });
  };

  const menuUser: MenuProps['items'] = [
    {
      label: <MenuItem>Hello, {email}</MenuItem>,
      key: 'email',
    },
  ];

  if (userInfo?.projects?.length !== projects?.length) {
    /**
     * @TODO Show regions based on the user's projects
     * rather than showing all if the count doesn't match.
     **/
    menuUser.push(
      {
        type: 'divider',
      },
      {
        label: 'Switch Region',
        key: 'region',
        children: hostnameOptions.map(({ urlRegionCode, region, label }) => ({
          key: urlRegionCode,
          label: <MenuItem
            accessoryRight={
              (currentRegion === region)
              && <CheckOutlined style={{ paddingLeft: 20 }} />
            }
          >
            {label}
          </MenuItem>,
          onClick: ev => onSwitchRegion(ev.key),
        })),
      },
    );
  }

  menuUser.push(
    {
      type: 'divider',
    },
    {
      label: (<>
        <LogoutOutlined style={{ marginRight: 5 }} />
        Logout
      </>),
      onClick: () => client.logout(),
      key: '1',
    },
  );

  return (
    <Header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.title}>
          <b>YNOMIA</b>
        </div>
        <div className={styles.headerRight}>
          <div className={styles.childrenColumn}>{children}</div>
          <div className={styles.menuProject}>
            <Dropdown menu={{ onClick, items: menuProjects }} placement="bottomRight">
              <div className={styles.dropdown}>
                {projectName?.toUpperCase() || ''} ({currentTenant?.toUpperCase() || ''})
              </div>
            </Dropdown>
          </div>
          <div className={styles.menuUser}>
            <Dropdown menu={{ items: menuUser }}>
              <div className={styles.dropdown}>
                <Avatar
                  className={styles.avatar}
                  shape="square"
                  size="large"
                  icon={<UserOutlined />}
                  src={picture}
                />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default MenuHeader;
