import { ContextDispatch, VisibilityFilter } from '../config/types';
import React, { createContext, useContext, useReducer } from 'react';
import visibilityFilterReducer from '../reducers/visibilityFilters';

export type VisibilityFilterStateType = {
  visibilityFiltersKeyedById: Map<string, VisibilityFilter>
  isFetchingVisibilityFilters: boolean
  isCreatingNewVisibilityFilter: boolean
  isUpdatingvisibilityFilter: boolean
};

const initialState: VisibilityFilterStateType = {
  visibilityFiltersKeyedById: new Map(),
  isCreatingNewVisibilityFilter: false,
  isFetchingVisibilityFilters: false,
  isUpdatingvisibilityFilter: false,
};

export const VisibilityFilterContext = createContext({ state: initialState, dispatch: ({}) => {} });

export const useVisibilityFilter = (): [VisibilityFilterStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(VisibilityFilterContext);
  return [state, dispatch];
};

export const VisibilityFilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(visibilityFilterReducer, initialState);

  return (
    <VisibilityFilterContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </VisibilityFilterContext.Provider>
  );
};
