import React, { createContext, useContext, useReducer } from 'react';
import { ContextDispatch } from '../config/types';
import CsvReducer from '../reducers/statusUpdate';
import { StatusUpdateMode } from '../config/constants';

export type StatusUpdateStateType = {
  isPlanningStatuses: boolean
  isApplyingStatuses: boolean
  plannedUpdatedStatus: any | null
  fileName: string | null
  importTime: Date | null
  fileContents: string | null
  statusUpdateMode: StatusUpdateMode
  filtersStatusUpdate: Array<string>
  filtersStatusUpdateTable: Array<string>
  showChangesOnly: boolean
  error: {
    status: number
    body: {
      code: string
      msg: string
    }
  } | null
};

const initialState: StatusUpdateStateType = {
  isPlanningStatuses: false,
  isApplyingStatuses: false,
  plannedUpdatedStatus: null,
  fileName: null,
  importTime: null,
  fileContents: null,
  statusUpdateMode: 'default',
  filtersStatusUpdate: [],
  filtersStatusUpdateTable: [],
  showChangesOnly: false,
  error: null,
};

export const StatusUpdateContext = createContext({ state: initialState, dispatch: ({}) => {} });

export const useStatusUpdate = (): [StatusUpdateStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(StatusUpdateContext);
  return [state, dispatch];
};

export const StatusUpdateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CsvReducer, initialState);

  return (
    <StatusUpdateContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </StatusUpdateContext.Provider>
  );
};
