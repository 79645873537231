import {
  getAssetStatusesKeyedById,
  getCurrentFeatureFilters,
  getCustomColumns,
  getListViewColumns,
} from '..';
import { getColumnFilters, getFormattedDataSource } from '../../utils';
import { getFilteredAssets, getFilteredAssetsContext } from '.';
import { createSelector } from 'reselect';
import { getLinkedDevicesKeyedByAssetId } from '../devices';
import map from '../map';

export const getFilteredAssetsForListViewTableFilter = createSelector(
  [
    getCurrentFeatureFilters,
    getFilteredAssetsContext,
  ],
  (
    currentFeatureFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      assetLayerFilter,
      layerChildFilter,
      supplierFilter,
      multiSearchModeFilter,
    } = currentFeatureFilters;
    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      assetLayerFilter,
      layerChildFilter,
      supplierFilter,
      multiSearchModeFilter,
    });
  },
);

export const getUnfilteredDataSource = createSelector(
  [
    getCustomColumns,
    map.layersKeyedById,
    map.assetsKeyedById,
    getAssetStatusesKeyedById,
    getLinkedDevicesKeyedByAssetId,
  ],
  (
    customColumns,
    layersKeyedById,
    assetsKeyedById,
    assetStatusesKeyedById,
    linkedDevicesKeyedByAssetId,
  ) => {
    const res = getFormattedDataSource(
      [...assetsKeyedById.values()],
      customColumns,
      layersKeyedById,
      assetsKeyedById,
      assetStatusesKeyedById,
      linkedDevicesKeyedByAssetId,
    );
    return res;
  },
);

export const getColumnFiltersForAssetsTable = createSelector(
  [
    map.layersKeyedById,
    getCurrentFeatureFilters,
    map.timezone,
    getUnfilteredDataSource,
    getListViewColumns,
  ],
  (
    layersKeyedById,
    currentFeatureFilters,
    timezone,
    listViewDataSource,
    listViewColumns,
  ) => {
    const { typeFilter, tableSorter, tableFilter } = currentFeatureFilters;
    return getColumnFilters(
      layersKeyedById,
      tableFilter,
      tableSorter,
      timezone,
      typeFilter,
      listViewDataSource,
      listViewColumns,
    );
  },
);

export const getFilteredAssetsForListView = createSelector(
  [
    getCurrentFeatureFilters,
    getFilteredAssetsContext,
  ],
  (
    currentFeatureFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      assetLayerFilter,
      tableFilter,
      layerChildFilter,
      supplierFilter,
      multiSearchModeFilter,
    } = currentFeatureFilters;
    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      assetLayerFilter,
      tableFilter,
      layerChildFilter,
      supplierFilter,
      multiSearchModeFilter,
    });
  },
);

export const getDataSourceForListView = createSelector(
  [
    getFilteredAssetsForListView,
    getCustomColumns,
    map.layersKeyedById,
    map.assetsKeyedById,
    getAssetStatusesKeyedById,
    getLinkedDevicesKeyedByAssetId,
  ],
  (
    filteredAssetsForAssetsTable,
    customColumns,
    layersKeyedById,
    assetsKeyedById,
    assetStatusesKeyedById,
    linkedDevicesKeyedByAssetId,
  ) => {
    return getFormattedDataSource(
      filteredAssetsForAssetsTable,
      customColumns,
      layersKeyedById,
      assetsKeyedById,
      assetStatusesKeyedById,
      linkedDevicesKeyedByAssetId,
    );
  },
);
