import { ContextDispatch, Layer } from '../config/types';
import React, { createContext, useContext, useReducer } from 'react';
import LayerReducer from '../reducers/layers';

export type LayerStateType = {
  layersKeyedById: Map<string, Layer>
  isFetchingLayers: boolean
};

const initialState: LayerStateType = {
  layersKeyedById: new Map(),
  isFetchingLayers: false,
};

export const LayerContext = createContext({ state: initialState, dispatch: ({}) => {} });

export const useLayer = (): [LayerStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(LayerContext);
  return [state, dispatch];
};

export const LayerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LayerReducer, initialState);

  return (
    <LayerContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </LayerContext.Provider>
  );
};
