import {
  AssetStatusFilter, ContextDispatch, DataType, Layer, ToggleObservation,
} from '../config/types';
import type { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import React, { createContext, useContext, useReducer } from 'react';
import type { Dayjs } from 'dayjs';
import filterReducer from '../reducers/filters';

export type PageFilterType = {
  searchTags: Array<string>
  searchText: string,
  dateFilter: [Dayjs, Dayjs] | []
  typeFilter: string
  assetStatusFilter: AssetStatusFilter
  observationTypeFilter: Map<string, ToggleObservation>,
  multiSearchModeFilter: 'and' | 'or'
  assetLayerFilter: Array<string>
  layerChildFilter: Layer | null
  tableFilter: Record<string, FilterValue | null>
  tableSorter: SorterResult<DataType> | null
  pagination: TablePaginationConfig
  supplierFilter: Array<string>
  timeTravelDate: Dayjs | null
};

export type FilterStateType = {
  currentFeature: string
  filters: {
    [feature: string]: PageFilterType
  }
} | {};

const initialState: FilterStateType = {};

export const FilterContext = createContext({ state: initialState, dispatch: ({}) => {} });

export const useFilter = (): [FilterStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(FilterContext);
  return [state, dispatch];
};

export const FilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(filterReducer, initialState);

  return (
    <FilterContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
