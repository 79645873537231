import { getAssetTypes, getCurrentFeatureFilters } from '..';
import { getAssetsKeyedByStatus, isFilteredByDateFilter } from '../../utils';
import { getFilteredAssets, getFilteredAssetsContext } from './';
import { RawObservationRecord } from '../../config/types';
import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import { getSelectedAssetType } from '../selectedType';
import { roundValue } from '@ynomia/core/dist/utils';

const getFilteredAssetsForAssetCurrentStatusSummary = createSelector(
  [
    getCurrentFeatureFilters,
    getFilteredAssetsContext,
  ],
  (
    currentFeatureFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      observationTypeFilter,
      assetLayerFilter,
      tableFilter,
      layerChildFilter,
      supplierFilter,
      multiSearchModeFilter,
    } = currentFeatureFilters;

    const filteredAssets = getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      observationTypeFilter,
      assetLayerFilter,
      tableFilter,
      layerChildFilter,
      supplierFilter,
      multiSearchModeFilter,
    });
    return filteredAssets
      .filter((asset)=> {
        const obs = asset.observations?.find(({ to })=> {
          return to === asset.status;
        });
        const date = obs?.date;
        const dateFormat = date && new Date(date);
        return (dateFilter?.length !== 2)
              || dateFormat && isFilteredByDateFilter(dateFormat, dateFilter);
      })
      .sort((a, b) => String(a.label).localeCompare(String(b.label)));
  },
);

const getFilteredAssetsForAssetStatusSummary = createSelector(
  [
    getCurrentFeatureFilters,
    getFilteredAssetsContext,
  ],
  (
    currentFeatureFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      observationTypeFilter,
      assetLayerFilter,
      tableFilter,
      layerChildFilter,
      supplierFilter,
      multiSearchModeFilter,
    } = currentFeatureFilters;
    const filteredAssets = getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      observationTypeFilter,
      typeFilter,
      assetLayerFilter,
      tableFilter,
      layerChildFilter,
      supplierFilter,
      multiSearchModeFilter,
    });
    return filteredAssets.sort((a, b) => String(a.label).localeCompare(String(b.label)));
  },
);

const getObservationStatusUpdateKeyedByStatuses = createSelector(
  [getCurrentFeatureFilters, getAssetTypes, getFilteredAssetsForAssetStatusSummary],
  (currentFeatureFilters, assetTypes, assetsArray) => {
    const { dateFilter } = currentFeatureFilters;
    const observationStatusUpdateKeyedByStatuses = new Map();

    if (assetTypes && assetsArray) {
      const statusesArray = assetTypes.map(assetType => assetType.statuses!).flat();
      const observationsArray: Array<RawObservationRecord> = flatten(
        assetsArray.map((asset) => {
          const { observations } = asset;
          // Omit repeat observations within the same asset
          const uniqueObservations: Array<RawObservationRecord> = [];
          const uniqueStatusIds = new Set<string>();
          observations?.forEach((obs) => {
            const date = obs.date;
            const dateCheck = (dateFilter?.length !== 2)
                || (date && isFilteredByDateFilter(new Date(date), dateFilter));
            if (obs.to && !uniqueStatusIds.has(obs.to) && dateCheck) {
              uniqueStatusIds.add(obs.to);
              uniqueObservations.push(obs);
            }
          });
          return uniqueObservations;
        }),
      );

      statusesArray?.forEach(({ id }) => {
        const observationsFilteredByStatusId = observationsArray.filter(({ to }) => {
          return id === to;
        });
        observationStatusUpdateKeyedByStatuses.set(id, observationsFilteredByStatusId);
      });
    }

    return observationStatusUpdateKeyedByStatuses as Map<string, Array<RawObservationRecord>>;
  },
);

const getAssetsKeyedByStatusForCurrentAssetStatusSummary = createSelector(
  [getAssetTypes, getFilteredAssetsForAssetCurrentStatusSummary],
  getAssetsKeyedByStatus,
);

export const getAssetStatusSummary = createSelector(
  [
    getCurrentFeatureFilters,
    getAssetsKeyedByStatusForCurrentAssetStatusSummary,
    getFilteredAssetsForAssetStatusSummary,
    getSelectedAssetType,
    getObservationStatusUpdateKeyedByStatuses,
  ],
  (
    currentFeatureFilters,
    assetsKeyedByStatus,
    assetsArray,
    selectedAssetType,
    observationStatusUpdateKeyedByStatuses,
  ) => {
    const { typeFilter, dateFilter } = currentFeatureFilters;

    if (!typeFilter || !assetsArray) {
      return [];
    }
    const { statuses } = selectedAssetType;
    const hasDateFilter = dateFilter?.length === 2;

    return statuses ? statuses.map((status) => {
      const { id, label, color } = status;
      const currentOccurrence = assetsKeyedByStatus.get(id)?.length || 0;
      const assetCount = assetsArray?.length;
      const defaultCumulativeOccurrence = hasDateFilter ? 0 : assetCount;
      const cumulativeOccurrence = status.default ?
        defaultCumulativeOccurrence : (observationStatusUpdateKeyedByStatuses.get(id)?.length || 0);
      const currentOccurrencePercentage = assetCount && roundValue(
        (currentOccurrence * 100) / assetCount,
        1,
      );
      const cumulativeOccurrencePercentage = assetCount && roundValue(
        (cumulativeOccurrence * 100) / assetCount,
        1,
      );
      return {
        id,
        label,
        color,
        currentOccurrence,
        currentOccurrencePercentage,
        cumulativeOccurrence,
        cumulativeOccurrencePercentage,
      };
    }) : [];
  },
);
