import { SET_DEVICES, SET_FETCHING_DEVICES } from '../actions/actionsTypes';
import { Device } from '@ynomia/core/dist/device';

export default (state, action) => {
  switch (action.type) {
    case SET_DEVICES:
      const devicesKeyedById = new Map<string, Device>([
        ...state.devicesKeyedById,
        ...action.payload,
      ]);

      return {
        ...state,
        devicesKeyedById,
      };
    case SET_FETCHING_DEVICES:
      return {
        ...state,
        isFetchingDevices: action.payload,
      };
    default:
      return state;
  }
};
