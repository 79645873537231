import React, { createContext, useContext, useReducer } from 'react';
import { Blueprint } from '@ynomia/core/dist/project/blueprint';
import ConfigurationReducer from '../reducers/configuration';
import { ContextDispatch } from '../config/types';
import { Project } from '@ynomia/core/dist/blueprint';


export type ConfigurationStateType = {
  changes: Project | undefined
  isUpdating: boolean
  isSyncing: boolean
  isCloning: boolean
  blueprintUpdate: Blueprint | null
  blueprintSync: Blueprint | null
  blueprintSyncPlan: Blueprint | null
  isFetchingBlueprint: boolean
};

const initialState: ConfigurationStateType = {
  changes: undefined,
  isUpdating: false,
  isSyncing: false,
  isCloning: false,
  blueprintUpdate: null,
  blueprintSync: null,
  blueprintSyncPlan: null,
  isFetchingBlueprint: false,
};

export const ConfigurationContext = createContext({ state: initialState, dispatch: ({}) => {} });

export const useConfiguration = (): [ConfigurationStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(ConfigurationContext);
  return [state, dispatch];
};

export const ConfigurationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ConfigurationReducer, initialState);

  return (
    <ConfigurationContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};
