import { SET_STATUS_TRACKING_LAYOUT } from '../actions/actionsTypes';

export default (state, action) => {
  switch (action.type) {
    case SET_STATUS_TRACKING_LAYOUT:
      return {
        ...state,
        assetPageLayout: action.payload,
      };
    default:
      return state;
  }
};
