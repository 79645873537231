import {
  INITIALIZE_FILTERS_PAGE,
  REMOVE_ALL_FILTERS,
  REMOVE_ASSET_LEVEL_FILTER,
  REMOVE_ASSET_STATUS_FILTER,
  REMOVE_CHILD_LAYERS_FILTER,
  REMOVE_FILTER_TYPE_CHANGE,
  REMOVE_OBSERVATION_TYPE_FILTER,
  SELECT_ASSET_STATUS_FILTER,
  SELECT_OBSERVATION_TYPE_FILTER,
  SET_ASSET_LAYER_FILTER,
  SET_CHILD_LAYERS_FILTER,
  SET_DATE_FILTER,
  SET_MULTI_SEARCH_MODE_FILTER,
  SET_SEARCH_TAGS,
  SET_SEARCH_TEXT,
  SET_SUPPLIER_FILTER,
  SET_TABLE_FILTER,
  SET_TABLE_PAGINATION,
  SET_TABLE_SORTER,
  SET_TIME_TRAVEL_DATE,
  SET_TYPE_FILTER,
} from '../actions/actionsTypes';
import { IS_MOBILE } from '../config/constants';

export default (state, action) => {
  const { feature } = action.payload;
  switch (action.type) {
    case INITIALIZE_FILTERS_PAGE:
      if (state[feature] && Object.keys(state[feature]).length > 2)
        return state;

      return {
        ...state,
        [feature]: {
          ...state[feature],
          searchTags: [],
          searchText: '',
          dateFilter: [],
          assetStatusFilter: {
            ids: [],
            toggle: null,
          },
          pagination: {
            current: 1,
            pageSize: IS_MOBILE ? 10 : 20,
          },
          multiSearchModeFilter: 'or',
          observationTypeFilter: new Map(),
          layerChildFilter: null,
          assetLayerFilter: '',
          tableFilter: {},
          tableSorter: null,
          supplierFilter: [],
          timeTravelDate: null,
        },
      };
    case SET_SEARCH_TAGS:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          searchTags: action.payload.searchTags,
        },
      };
    case SET_SEARCH_TEXT:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          searchText: action.payload.searchText,
        },
      };
    case SET_DATE_FILTER:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          dateFilter: action.payload.dateFilter,
        },
      };
    case SET_TIME_TRAVEL_DATE:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          timeTravelDate: action.payload.timeTravelDate,
        },
      };
    case SET_TYPE_FILTER:
      if (!state[feature]) {
        return {
          ...state,
          [feature]: {
            ...state[feature],
            searchTags: [],
            searchText: '',
            dateFilter: [],
            assetStatusFilter: {
              ids: [],
              toggle: null,
            },
            pagination: {
              current: 1,
              pageSize: IS_MOBILE ? 10 : 20,
            },
            multiSearchModeFilter: 'or',
            observationTypeFilter: new Map(),
            layerChildFilter: null,
            assetLayerFilter: '',
            typeFilter: action.payload.typeFilter,
            tableFilter: {},
            tableSorter: null,
            supplierFilter: [],
            timeTravelDate: null,
          },
        };
      }
      return {
        ...state,
        [feature]: {
          ...state[feature],
          typeFilter: action.payload.typeFilter,
        },
      };
    case SELECT_ASSET_STATUS_FILTER: {
      const { id, toggledValue } = action.payload;
      const { ids, toggle } = state[feature]?.assetStatusFilter;

      if (toggle !== toggledValue) {
        return {
          ...state,
          [feature]: {
            ...state[feature],
            assetStatusFilter: {
              toggle: toggledValue,
              ids: [id],
            },
          },
        };
      }

      const newIds = ids.includes(id)
        ? ids.filter(idFromState => idFromState !== id)
        : [...ids, id];

      if (newIds.length === 0) {
        return {
          ...state,
          [feature]: {
            ...state[feature],
            assetStatusFilter: {
              toggle: null,
              ids: [],
            },
          },
        };
      }

      return {
        ...state,
        [feature]: {
          ...state[feature],
          assetStatusFilter: {
            toggle,
            ids: newIds,
          },
        },
      };
    }
    case SELECT_OBSERVATION_TYPE_FILTER: {
      const { id, toggledValue } = action.payload;
      const { observationTypeFilter } = state[feature];
      const newObservationTypeFilter = new Map(observationTypeFilter);
      if (observationTypeFilter.has(id) && observationTypeFilter.get(id) === toggledValue) {
        newObservationTypeFilter.delete(id);
      } else {
        newObservationTypeFilter.set(id, toggledValue);
      }
      return {
        ...state,
        [feature]: {
          ...state[feature],
          observationTypeFilter: newObservationTypeFilter,
        },
      };
    }
    case SET_ASSET_LAYER_FILTER:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          assetLayerFilter: action.payload.layerId,
        },
      };
    case SET_CHILD_LAYERS_FILTER:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          layerChildFilter: action.payload.layer,
        },
      };
    case SET_SUPPLIER_FILTER:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          supplierFilter: action.payload.supplier,
        },
      };
    case SET_TABLE_FILTER:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          tableFilter: action.payload.tableFilter,
        },
      };
    case SET_TABLE_SORTER:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          tableSorter: action.payload.tableSorter,
        },
      };
    case SET_TABLE_PAGINATION:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          pagination: action.payload.pagination,
        },
      };
    case SET_MULTI_SEARCH_MODE_FILTER:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          multiSearchModeFilter: action.payload.multiSearchModeFilter,
        },
      };
    case REMOVE_FILTER_TYPE_CHANGE:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          searchTags: [],
          searchText: '',
          assetStatusFilter: {
            ids: [],
            toggle: null,
          },
          observationTypeFilter: new Map(),
          tableFilter: {},
          layerChildFilter: null,
          assetLayerFilter: '',
        },
      };
    case REMOVE_ALL_FILTERS:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          searchTags: [],
          searchText: '',
          dateFilter: [],
          assetStatusFilter: {
            ids: [],
            toggle: null,
          },
          observationTypeFilter: new Map(),
          layerChildFilter: null,
          assetLayerFilter: '',
          tableFilter: {},
          tableSorter: null,
          supplierFilter: [],
          timeTravelDate: null,
        },
      };
    case REMOVE_ASSET_STATUS_FILTER:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          assetStatusFilter: {
            toggle: null,
            ids: [],
          },
        },
      };
    case REMOVE_OBSERVATION_TYPE_FILTER:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          observationTypeFilter: new Map(),
        },
      };
    case REMOVE_ASSET_LEVEL_FILTER:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          assetLayerFilter: [],
        },
      };
    case REMOVE_CHILD_LAYERS_FILTER:
      return {
        ...state,
        [feature]: {
          ...state[feature],
          layerChildFilter: null,
        },
      };
    default:
      return state;
  }
};
