import { SET_CLIENT_CACHE } from '../actions/actionsTypes';

export default (state, action) => {
  switch (action.type) {
    case SET_CLIENT_CACHE:
      return {
        ...state,
        clientCache: action.payload,
      };
    case 'REMOVE_CLIENT_CACHE':
      return {
        clientCache: {},
      };
    default:
      return state;
  }
};
