import { Asset } from '../config/types';
import { Field } from '@ynomia/core/dist/blueprint';
import { createSelector } from 'reselect';
import map from './map';

export const getAssetsArray = createSelector(
  [map.assetsKeyedById],
  (assetsKeyedById) => {
    const keys = [...assetsKeyedById.keys()];
    return keys.map(id => assetsKeyedById.get(id)) as Array<Asset>;
  });

export const getAssetsKeyedByTypes = createSelector(
  [map.assetTypes, getAssetsArray],
  (assetTypes, assetArray) => {
    const assetsKeyedByTypes = new Map<string, Array<Asset>>();
    if (assetTypes && assetArray) {
      assetTypes.forEach(({ id }) => {
        const assetsFilteredByStatusId = assetArray.filter(({ type }) => type === id);
        assetsKeyedByTypes.set(id, assetsFilteredByStatusId);
      });
    }

    return assetsKeyedByTypes as Map<string, Array<Asset>>;
  },
);

export const getFieldsKeyedByTypes = createSelector(
  [map.assetTypes],
  (assetTypes) => {
    const fieldsKeyedByTypes = new Map();

    if (assetTypes) {
      assetTypes.forEach(({ id, fields }) => {
        fieldsKeyedByTypes.set(id, new Map(fields?.map(field => [field.id, field])));
      });
    }
    return fieldsKeyedByTypes as Map<string, Map<string, Field>>;
  },
);

export const getAssetsKeyedByTwinId = createSelector(
  [getAssetsArray],
  (assetArray) => {
    return new Map(assetArray.map((asset) => {
      const [twinId] = asset.destination?.twinID || '';
      return [twinId, asset];
    })) as Map<string, Asset>;
  },
);

export const getAssetsKeyedByLabel = createSelector(
  [getAssetsArray],
  (assetArray) => {
    return new Map(assetArray.map(asset => [asset.label, asset])) as Map<string, Asset>;
  },
);
