import {
  Button,
  ConfigProvider,
  DatePicker,
  InputRef,
  Radio,
  Select,
  Tooltip,
} from 'antd';
import {
  DoubleLeftOutlined, DoubleRightOutlined, HistoryOutlined, LeftOutlined, RightOutlined,
} from '@ant-design/icons';
import { LayoutIcon, TagInput } from '../../atoms';
import React, { useEffect, useRef, useState } from 'react';
import {
  checkFeatureTimeTravelControl,
  getAssetFiltersActive,
  getCurrentFeature,
  getCurrentFeatureFilters,
  getPageFeatures,
  getSelectedAssetType,
} from '../../../selectors';
import {
  convertTimezoneTimeToLocalizedTime,
  disabledDate,
  getProjectTimezone,
  toProjectDate,
} from '../../../utils';
import {
  removeAllFilters,
  setAssetPageLayout,
  setDateFilter,
  setMultiSearchMode,
  setSearchTags,
  setSearchText,
  setTimeTravelDate,
  setTypeFilter,
} from '../../../actions';
import { AssetTypeDropdown } from '../';
import { Dayjs } from 'dayjs';
import ReportsDropdown from './ReportsDropdown';
import { analytics } from '../../../services';
import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';
import { getContextStores } from '../../../context';
import locale from 'antd/es/locale/en_US';
import styles from './styles.module.less';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsUtc);

dayjs.updateLocale('en', {
  weekStart: 1,
});

const { RangePicker } = DatePicker;
const { Option } = Select;

const Filters = () => {
  /* Context */
  const contextStores = getContextStores();
  const {
    localStorageState,
    filterDispatch,
    localStorageDispatch,
  } = contextStores;

  /* Selectors */
  const isFeatureTimeTravelControl = checkFeatureTimeTravelControl(contextStores);
  const enableResetAllFilters = getAssetFiltersActive(contextStores);
  const PROJ_TZ = getProjectTimezone();

  const [needUpdate, setNeedUpdate] = useState<boolean>(false);
  const [displayedSearchText, setDisplayedSearchText] = useState<string>('');
  const textInput = useRef<InputRef>(null);
  const feature = getCurrentFeature(contextStores);
  const currentFeatureFilters = getCurrentFeatureFilters(contextStores);
  const {
    typeFilter,
    searchTags,
    searchText,
    dateFilter,
    timeTravelDate,
  } = currentFeatureFilters;

  const {
    timeTravel,
    dateRange,
    layoutSwitcher,
  } = getPageFeatures(contextStores);

  const {
    assetPageLayout,
  } = localStorageState;

  useEffect(() => {
    if (searchTags.length > 0) {
      textInput.current?.focus();
    }
  }, [textInput.current]);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchText(
      displayedSearchText, feature, filterDispatch,
    ), 200);
    if (!!displayedSearchText) analytics.trackEvent('Search Changed');
    return () => clearTimeout(timeOutId);
  }, [displayedSearchText]);

  useEffect(() => {
    if (searchText === '') {
      setDisplayedSearchText('');
    }
  }, [searchText]);

  const onRangeChange = (dates: null | (Dayjs | null)[]) => {
    analytics.trackEvent('Date Range Changed');
    if (dates && dates[0] && dates[1]) {
      const res = [
        toProjectDate(dates[0]).startOf('day'),
        toProjectDate(dates[1]).endOf('day'),
      ];
      setDateFilter(
        res as [Dayjs, Dayjs],
        feature,
        filterDispatch,
      );
    } else {
      setDateFilter([], feature, filterDispatch);
    }
  };

  const onTimeTravelChange = (date: Dayjs | undefined | null) => {
    if (date) {
      const newDate = toProjectDate(date).endOf('day');
      setTimeTravelDate(newDate, feature, filterDispatch);
    } else {
      setTimeTravelDate(null, feature, filterDispatch);
    }
  };


  const getRangePresets = (): Array<{
    label: string;
    value: [Dayjs, Dayjs];
  }> => {
    const lastMonth: number = dayjs().month() - 1;
    const lastWeek: number = dayjs().week() - 1;

    return [
      { label: 'Today', value: [dayjs(), dayjs()] },
      { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
      { label: 'This Week', value: [dayjs().weekday(0), dayjs()] },
      { label: 'This Month', value: [dayjs().startOf('month'), dayjs()] },
      {
        label: 'Last Week', value: [
          dayjs().week(lastWeek).startOf('week'),
          dayjs().week(lastWeek).endOf('week')],
      },
      {
        label: 'Last Month', value: [
          dayjs().month(lastMonth).startOf('month'),
          dayjs().month(lastMonth).endOf('month'),
        ],
      },
    ];
  };


  // Auto select the first type if nothing is selected
  const currentType = getSelectedAssetType(contextStores);
  const { id } = currentType;

  useEffect(() => {
    if (needUpdate) {
      setNeedUpdate(false);
    }
  }, [needUpdate]);

  useEffect(() => {
    if (!typeFilter) {
      setTypeFilter(id, feature, filterDispatch);
    }
  }, [typeFilter]);

  const selectAfter = (
    <Select
      defaultValue="or"
      size="large"
      onChange={e =>
        setMultiSearchMode(e as 'and' | 'or', feature, filterDispatch)}
    >
      <Option value="and">And</Option>
      <Option value="or">Or</Option>
    </Select>
  );

  const renderTags = () => {
    return <TagInput
      searchTags={searchTags}
      searchText={displayedSearchText}
      addonAfter={selectAfter}
      onChangeText={(text) => {
        setDisplayedSearchText(text);
      }}
      onChangeTags={(v) => {
        setSearchTags(v, feature, filterDispatch);
      }}
    />;
  };

  const endOfDay = dayjs().endOf('day');
  const dayjsConvertTimezoneTimeToLocalizedTime = (inputDate?: Dayjs | null) => inputDate
    ? dayjs(convertTimezoneTimeToLocalizedTime(inputDate.toString(),
      PROJ_TZ,
    ))
    : null;
  const localizedDateRangeTime: [Dayjs | null, Dayjs | null] = [
    dateFilter?.length && dayjsConvertTimezoneTimeToLocalizedTime(dateFilter[0] || null),
    dateFilter?.length && dayjsConvertTimezoneTimeToLocalizedTime(dateFilter[1] || null),
  ];
  const localizedTimeTravelDate = dayjsConvertTimezoneTimeToLocalizedTime(timeTravelDate);

  return (
    <div className={styles.container}>
      <div className={styles.filtersContainer}>
        <AssetTypeDropdown />
      </div>
      <div className={styles.searchContainer}>
        {renderTags()}
      </div>
      {dateRange && <div className="rangeFilter">
          <ConfigProvider locale={locale}>
            <Tooltip title={'Filter by asset updated date in the period of'}>
              <RangePicker
                presets={getRangePresets()}
                style={{ height: '100%', width: 190 }}
                format="DD/MM/YYYY"
                onChange={dates => onRangeChange(dates as null | (Dayjs | null)[])}
                defaultValue={dateFilter?.length ? dateFilter : undefined}
                value={(dateFilter?.length === 2) ? localizedDateRangeTime : undefined}
                placement={'bottomRight'}
                disabledDate={current => disabledDate(current, 'past')}
                disabled={!!timeTravelDate}
              />
            </Tooltip>
          </ConfigProvider>
        </div>
      }

      {isFeatureTimeTravelControl && timeTravel && <div className={styles.timeTravelContainer}>
        <Button
          onClick={() => {
            const date = localizedTimeTravelDate || endOfDay;
            analytics.trackEvent('Time Travel Changed', { action: 'Month Subtracted' });
            onTimeTravelChange(date.subtract(1, 'month'));
          }}
          icon={<DoubleLeftOutlined />}
        />
        <Button
          onClick={() => {
            analytics.trackEvent('Time Travel Changed', { action: 'Day Subtracted' });
            const date = localizedTimeTravelDate || dayjs().endOf('day');
            onTimeTravelChange(date.subtract(1, 'day'));
          }}
          icon={<LeftOutlined />}
        />
        <Tooltip
          title={'You can go back in time to see the state of the project at the time chosen'}
        >
          <DatePicker
            onChange={(ev) => {
              analytics.trackEvent('Time Travel Changed', { action: 'Changed' });
              onTimeTravelChange(ev);
            }}
            format="DD/MM/YYYY"
            suffixIcon={<HistoryOutlined />}
            showNow={false}
            placeholder="Time travel"
            disabledDate={current => disabledDate(current, 'past')}
            value={localizedTimeTravelDate}
            size='large'
          />
        </Tooltip>
        <Button
          disabled={!timeTravelDate || !timeTravelDate.add(1, 'day').isSameOrBefore(endOfDay)}
          onClick={() => {
            analytics.trackEvent('Time Travel Changed', { action: 'Day Added' });
            onTimeTravelChange(localizedTimeTravelDate?.add(1, 'day'));
          }}
          icon={<RightOutlined />}
        />
        <Button
          disabled={!timeTravelDate || !timeTravelDate.add(1, 'month').isSameOrBefore(endOfDay)}
          onClick={() => {
            analytics.trackEvent('Time Travel Changed', { action: 'Month Added' });
            onTimeTravelChange(localizedTimeTravelDate?.add(1, 'month'));
          }}
          icon={<DoubleRightOutlined />}
        />
      </div>}

      <div className={styles.rightContainer}>
        <Button
          onClick={() => {
            analytics.trackEvent('Reset All Filters Clicked');
            removeAllFilters(feature, filterDispatch);
          }}
          disabled={!enableResetAllFilters}
          style={{ height: 40 }}
        >
          Reset All Filters
        </Button>
        {layoutSwitcher && <>
          <div className={styles.divider} />
          <Radio.Group
            value={assetPageLayout}
            onChange={(ev) => {
              const selection = ev.target.value;
              analytics.trackEvent('Page Layout Changed', { selection });
              setAssetPageLayout(selection, localStorageDispatch);
            }}
            size="large"
            style={{ whiteSpace: 'nowrap' }}
          >
            <Radio.Button value="thirds"><LayoutIcon name="thirds" /></Radio.Button>
            <Radio.Button value="default"><LayoutIcon name="default" /></Radio.Button>
            <Radio.Button value="full"><LayoutIcon name="full" /></Radio.Button>
          </Radio.Group>
          <div className={styles.divider} />
        </>}
        <div className={styles.rightContainerRightFloat}>
          <ReportsDropdown />
        </div>
      </div>
    </div>
  );
};

export default Filters;