export const calculateLoadingPercentage = (
  modelByKeys: { [key: string]: any },
  individualModels: Array<string>,
): number  => {
  const sum = individualModels.reduce((accumulator, key) => {
    return accumulator + (modelByKeys[key]?.loading || 0);
  }, 0);

  return Math.trunc(sum / individualModels.length) || 0;
};

