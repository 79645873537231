import React, { FC } from 'react';
// Do not "import * as mdi" as that will bloat the build size
import {
  mdiAccountArrowLeftOutline,
  mdiAccountArrowRightOutline,
  mdiAlertRemoveOutline,
  mdiArrowDecisionAutoOutline,
  mdiCalendarQuestion,
  mdiCash,
  mdiClipboardCheckOutline,
  mdiClockIn,
  mdiClockOut,
  mdiCupWater,
  mdiDatabasePlus,
  mdiDomain,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiGrid,
  mdiLightbulbOnOutline,
  mdiLinkVariant,
  mdiLinkVariantOff,
  mdiRuler,
  mdiSync,
  mdiTruckOutline,
} from '@mdi/js';
import { BeakerIcon } from '@primer/octicons-react';
import Icon from '@mdi/react';
import { MapPin } from 'react-feather';
import { QuestionCircleOutlined } from '@ant-design/icons';

const materialIcons = {
  mdiAccountArrowLeftOutline,
  mdiAccountArrowRightOutline,
  mdiAlertRemoveOutline,
  mdiArrowDecisionAutoOutline,
  mdiCalendarQuestion,
  mdiCash,
  mdiClipboardCheckOutline,
  mdiClockIn,
  mdiClockOut,
  mdiCupWater,
  mdiDatabasePlus,
  mdiDomain,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiGrid,
  mdiLightbulbOnOutline,
  mdiLinkVariant,
  mdiLinkVariantOff,
  mdiRuler,
  mdiSync,
  mdiTruckOutline,
};

interface Props {
  name: string
  type: string
  color?: string
}

const ObservationIcon: FC<Props> = ({ name, type, color }) => {
  const iconName = `mdi${
    name.split('-').map(word => word[0].toUpperCase() + word.slice(1)).join('')}`;
  const customIcon = materialIcons[iconName];

  if (type === 'Feather' && name === 'map-pin') {
    // Map pin is the only feather icon we use at the moment
    return <MapPin size={14} style={{ color: color }}/>;
  }

  if (type === 'Octicons' && name === 'beaker') {
    return <div style={{ marginTop: -5, color: color }}>
      <BeakerIcon size={12} />
    </div>;
  }

  if (customIcon && type === 'MaterialCommunityIcons') {
    return <Icon
      path={customIcon}
      size={0.6}
      color={color}
    />;
  }

  return <QuestionCircleOutlined />;
};

export default ObservationIcon;
