import { Image, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { ACCESS_TOKEN_STORAGE_KEY } from '../../../config/constants';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './styles.module.less';

interface Props {
  src: string,
  useYnomiaAccessToken?: boolean
}

const ImagePreview: FC<Props> = ({ src, useYnomiaAccessToken }) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  const [imgSrc, setImgSrc] = useState<string>('');

  useEffect(() => {
    fetch(src, {
      method: 'GET',
      headers: useYnomiaAccessToken
        ? { Authorization: `Bearer ${accessToken}` }
        : undefined,
    }).then(response => response.blob())
      .then(blob => setImgSrc(URL.createObjectURL(blob)));
  }, []);

  return  <div className={styles.imagePreviewContainer}>
    {imgSrc ?
      <Image
        width={100}
        height={100}
        src={imgSrc}
       className={styles.image}
      /> : <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    }
    </div>;
};

export default ImagePreview;
